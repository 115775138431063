<template>
  <div class="special-ico" v-if="goodsInfo">
    <Header titleurl="special" v-if="fromoa != '1'" ref="headerchild" />
    <div class="section_head" v-if="cmpinfo != null">
      <router-link
        target="_blank"
        :to="{ path: '/company/detail', query: { id: goodsInfo.companyId } }"
      >
        {{ cmpinfo.name }}
      </router-link>
    </div>
    <div class="content">
      <div class="wrap-box">
        <div class="section ">
          <div class="goods-box ">
            <div class="picbox">
              <magnifier :imgList="slidesimages"></magnifier>

              <!-- <img class="bigImg" :src="imgurl" width="350" height="350"> -->
              <!-- <div class="hr"></div>
              <ul>
                <li :class="{'current':currindex === key}" @click="onimgpreview(item,key)"
                  v-for="(item,key) in imageList" :key="key"><img :src="item.imagePath" width="50" height="50">
                </li>
              </ul> -->
              <!-- <ProductZoomer :base-images="images" :base-zoomer-options="zoomerOptions" /> -->
            </div>
            <div class="descbox">
              <div class="thing_name">
                {{ goodsInfo.typeName }}/{{ goodsInfo.subTypeName }}/{{
                  goodsInfo.customName
                }}
              </div>
              <div class="thing_konwn"></div>
              <div class="thing_place">
                <div class="thing_every">
                  <span class="font_grey">价格</span>
                  <span class="font_grey thing_newprice"
                    ><span>¥</span><span>{{ rprice }}</span></span
                  >
                  <span
                    class="font_grey thing_newprice originprice"
                    v-show="rprice != price"
                    ><span>¥</span><span>{{ price }}</span></span
                  >
                </div>
                <div class="thing_sale thing_margin" v-if="false">
                  <span class="font_grey">月销量</span>
                  <span class="font_#e4393c">{{ goodsInfo.saleCount }}</span>
                </div>
              </div>
              <div
                class="thing_margin thing-sku margin-right"
                v-for="(item, key) in goodsInfo.customAttrList"
                :key="key"
              >
                <span class="font_grey  thing-sku-title">{{
                  item.attrTitle
                }}</span>
                <ul class="thing-sku-customattr">
                  <li>
                    <span
                      class="customname "
                      :class="[
                        dataform[item.attrCode + '_id'] == custom.id
                          ? 'selected'
                          : '',
                        item.attrTitle == '款式' ? 'customstyle' : ''
                      ]"
                      @click="
                        onclickattrdetail(
                          item.attrTitle == '款式',
                          item,
                          custom
                        )
                      "
                      v-for="(custom, keycustom) in item.customAttrDetailList"
                      :key="keycustom"
                    >
                      <template v-if="custom.imageList.length > 0">
                        <el-image
                          v-if="item.attrTitle == '款式'"
                          :src="custom.imageList[0].imagePath"
                          fit="fill"
                        >
                        </el-image>
                      </template>
                      <span
                        :class="
                          item.attrTitle == '款式' ? 'customstyletit' : ''
                        "
                        >{{ custom.customName }}</span
                      >
                    </span>
                  </li>
                </ul>
                <div class="clearfix"></div>
                <template v-if="item.attrTitle != '款式'">
                  <div
                    class="thing_margin thing-sku margin-right "
                    :data-count="item.customAttrDetailList.length"
                  >
                    <!-- <span class="font_grey thing-sku-title"></span> -->
                    <ul
                      class="sku-image"
                      v-show="dataform[item.attrCode + '_id'] == custom.id"
                      v-for="(custom, keycustom) in item.customAttrDetailList"
                      :key="keycustom"
                    >
                      <li>
                        <span
                          :class="
                            dataform[item.attrCode + '_img'] == img.id
                              ? 'selected'
                              : ''
                          "
                          @click="onclickattrdetailimg(item, img)"
                          class="customname  customstyle"
                          v-for="(img, keyimg) in custom.imageList"
                          :key="keyimg"
                        >
                          <div class="imgMerror">
                            <div
                              class="smallDiv"
                              :class="'smallDiv' + img.id"
                              @mousemove="move($event, img.id)"
                              @mouseenter="enter(img.id)"
                              @mouseleave="leave(img.id)"
                            >
                              <img :src="img.imagePath" alt="" />
                              <div
                                class="imgMask"
                                :class="'imgMask' + img.id"
                              ></div>
                            </div>
                            <div class="bigDiv" :class="'bigDiv' + img.id">
                              <img
                                :src="img.imagePath"
                                class="bigImg"
                                :class="'bigImg' + img.id"
                              />
                            </div>
                          </div>

                          <span class="customstyletit">{{
                            img.imageName
                          }}</span>
                        </span>
                      </li>
                    </ul>
                    <div class="clearfix"></div>
                  </div>
                </template>
                <template v-if="item.attrCode == '数据采集' && ismeasure">
                  <div class="thing-sku-customattr measure-curr">
                    <div class="measure" @click="onloadcollectlist()">
                      <span class="currmeaure"> {{ currmeasure }}</span>
                      <i class="icon-edit el-icon-edit"></i>
                    </div>
                    <div data-v-58beaa13="" class="clearfix"></div>
                  </div>
                </template>
              </div>
              <div class="summary-line"></div>
              <div class="thing_margin shoppingbtns">
                <span class="shoppingnum">
                  <el-input-number
                    :min="1"
                    :max="200"
                    @change="handleChange"
                    v-model="buynum"
                    controls-position="right"
                    placeholder=""
                  />
                  件
                </span>
                <el-button
                  class="btnbuy"
                  @click="ontobuy(goodsInfo.id)"
                  v-if="goodsInfo.saleState == 1"
                  >立即购买</el-button
                >
                <el-button
                  type="info"
                  v-if="goodsInfo.saleState == 0"
                  class="saleout"
                  >已下架</el-button
                >
              </div>
              <div class="thing_margin margin-right shoppingother">
                <span class="font_grey">服务承诺</span>
                <span class="font-dimgrey">破损包退</span>
                <span class="font-dimgrey">正品保障</span>
                <span class="font-dimgrey">急速退款</span>
                <span class="font-dimgrey">赠运费险</span>
              </div>
              <div></div>
            </div>
            <div class="clear"></div>
          </div>
          <div class="detail-box">
            <div class="content-left">
              <div class="content_left_first" v-if="cmpinfo != null">
                <span class="content_store_name">
                  <router-link
                    target="_blank"
                    :to="{
                      path: '/company/detail',
                      query: { id: goodsInfo.companyId }
                    }"
                  >
                    {{ cmpinfo.name }}
                  </router-link>
                </span>
                <div class="content_hr"></div>
                <!-- <div class="content_grade">6</div> -->
                <span class="store-name"></span>
                <div class="content_mfw">
                  <span>描述</span>
                  <span>服务</span>
                  <span>物流</span>
                </div>
                <div class="content_fs">
                  <span>4.9↑</span>
                  <span>4.9↑</span>
                  <span>4.9↑</span>
                </div>
              </div>
            </div>
            <div class="content-right">
              <ul class="myTabs nav nav-tabs">
                <li class="active">
                  <a href="#shopdetail" data-toggle="tab">商品详情</a>
                </li>
                <li class="dropdown" style="display:none;">
                  <a
                    href="#"
                    id="myTabDrop1"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    手机购买
                    <b class="caret"></b>
                  </a>
                  <ul
                    class="dropdown-menu"
                    role="menu"
                    aria-labelledby="myTabDrop1"
                  >
                    <li>
                      <a href="#wechat" tabindex="-1" data-toggle="tab"
                        >微信支付</a
                      >
                    </li>
                    <li>
                      <a href="#alipay" tabindex="-1" data-toggle="tab"
                        >支付宝支付</a
                      >
                    </li>
                  </ul>
                </li>
              </ul>
              <div id="mycontent" class="tab-content">
                <div class="tab-pane fade in active">
                  <div class="shopnumber">
                    <div style="margin-bottom: 10px;"></div>
                    <div class="margin-bottom: 15px;">
                      <span style="font-size: 10px;font-weight: 700;color:#999;"
                        >产品参数:</span
                      >
                    </div>
                    <div class="content_tab">
                      <div class="content_tab_left">
                        <div
                          class="content_tab_bottom"
                          v-for="(item, key) in goodsInfo.specsList"
                          :key="key"
                        >
                          <span>{{ item.specsName }}：</span
                          ><span :title="item.specsValue">{{
                            item.specsValue
                          }}</span>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="product_desc contentbox">
                <div v-html="customDescribe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <el-dialog
      :visible.sync="collectdialogVisible"
      width="900px"
      class=""
      title="新增数据采集"
      @close="onpopupcollectclose"
      :close-on-click-modal="false"
    >
      <Collecter v-if="collectdialogVisible" :id="collectid" />
    </el-dialog>
    <el-dialog
      :visible.sync="collectlistdialogVisible"
      width="700px"
      class="collectlist"
      title="选择数据采集"
      @close="onpopupcollectlistclose"
      :close-on-click-modal="false"
    >
      <h2 style="">
        <el-button
          class="btnadd"
          @click="onaddcollect"
          icon="el-icon-edit"
          size="mini"
          >新增数据</el-button
        >
      </h2>
      <div class="step-cont">
        <div class="consignee-content">
          <div class="consignee-scrollbar">
            <div class="ui-scrollbar-main">
              <div class="consignee-scroll">
                <div class="consignee-cont">
                  <ul>
                    <li
                      v-for="(item, index) in collectlist"
                      :key="index"
                      @mouseenter="oncollectopbtnsshowenter(index)"
                      @mouseleave="oncollectopbtnsleave"
                    >
                      <div
                        class="consignee-item"
                        @click="oncollectchecked(item)"
                        :class="measureid == item.id ? 'item-selected' : ''"
                      >
                        <span limit="8">{{ item.realname }}</span
                        ><b></b>
                      </div>
                      <div class="addr-detail">
                        <span class="addr-name" limit="6">{{
                          item.realname
                        }}</span>
                        <span class="addr-info" limit="45"
                          >{{ ' ' + item.sex
                          }}{{ ' 年龄：' + ongetage(item.birthday)
                          }}{{ ' 身高：' + item.height + 'CM'
                          }}{{ ' 体重：' + item.weight + 'KG' }}
                        </span>
                        <span class="addr-tel">{{ item.shape }}</span>
                      </div>
                      <div
                        class="op-btns"
                        :class="
                          iscollectopbtnsshowindex == index
                            ? 'op-btns-show'
                            : ''
                        "
                      >
                        <a
                          href="javascript:;"
                          class="ftx-05 edit-consignee"
                          @click="oninitcollect(item.id)"
                          >编辑</a
                        >
                        <a
                          href="javascript:;"
                          class="ftx-05 del-consignee "
                          style="margin-left:5px"
                          @click="onusermeasuredatadel(item.id)"
                          >删除</a
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <Footer v-if="fromoa != '1'" />
  </div>
</template>

<script>
import { companygetcompanyinfo } from '@/api/company.js';
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';
import magnifier from '@/components/magnifier.vue';
import Collecter from '@/components/collectedit.vue';
import { usermeasuredatagetlist, usermeasuredatadel } from '@/api/user';
import {
  productgetcustomdetail,
  productgetspecialproductlist
} from '@/api/product';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    magnifier,
    Collecter
  },
  data() {
    return {
      iscollectopbtnsshowindex: 0,
      collectlistdialogVisible: false,
      collectlist: null,
      collectid: 0,
      collectdialogVisible: false,
      ismeasure: false,
      currmeasure: '请添加采集数据',
      measureid: 0,
      fromoa: '',
      slidesimages: [],
      zoomerOptions: {
        zoomFactor: 3,
        pane: 'pane',
        hoverDelay: 300,
        namespace: 'zoomer-bottom',
        move_by_click: false,
        scroll_items: 4,
        choosed_thumb_border_color: '#dd2c00',
        scroller_position: 'bottom',
        zoomer_pane_position: 'right'
      },
      cid: 0,
      cmpinfo: null,
      active: 4,
      firstShow: true,
      show: false,
      height: '600',
      productlist: [],
      goodsInfo: {},
      hotList: [],
      buyCount: '',
      imageList: [],
      customDescribe: '',
      imgurl: '',
      currindex: 0,
      isSelected: true,
      buynum: 1,
      uniqueNo: '',
      customName: '',
      id: 0,
      dataform: {},
      price: 0,
      rprice: 0,
      detailinfoimglist: []
    };
  },
  methods: {
    onloadcollectlist() {
      let accessToken = getCookieByName('access_token');
      if (!accessToken) {
        this.$refs.headerchild.dialogVisible = true;
        this.$refs.headerchild.onappCodeRefreshButton();
        return;
      }
      this.collectlistdialogVisible = true;
    },
    onaddcollect() {
      this.collectdialogVisible = true;
      this.collectid = 0;
    },
    oncollect() {
      this.collectdialogVisible = false;
      this.collectid = 0;
      this.onusermeasuredatagetlist();
    },
    oninitcollect(item) {
      this.collectdialogVisible = true;
      this.collectid = item;
    },
    oncollectopbtnsshowenter(index) {
      this.iscollectopbtnsshowindex = index;
    },
    oncollectopbtnsleave() {
      this.iscollectopbtnsshowindex = -1;
    },
    oncollectchecked(item) {
      this.currcollect = item;
      this.measureid = item.id;
      this.collectlistdialogVisible = false;
      this.currmeasure =
        item.realname +
        ' ' +
        item.sex +
        ' 年龄：' +
        this.ongetage(item.birthday) +
        ' 身高：' +
        item.height +
        'CM' +
        ' 体重：' +
        item.weight +
        'KG';
    },
    onpopupcollectclose() {},
    onpopupcollectlistclose() {},
    ongetage(birthday) {
      var birthdays = new Date(birthday);
      var d = new Date();
      var age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);

      return age;
    },
    enter(item) {
      console.log(item);
      let imgMaskDom = document.querySelector('.imgMask' + item);
      let bigDivDom = document.querySelector('.bigDiv' + item);
      imgMaskDom.style.display = 'block';
      bigDivDom.style.display = 'block';
    },
    leave(item) {
      let imgMaskDom = document.querySelector('.imgMask' + item);
      let bigDivDom = document.querySelector('.bigDiv' + item);
      imgMaskDom.style.display = 'none';
      bigDivDom.style.display = 'none';
    },
    move(e, item) {
      let smallDivDom = document.querySelector('.smallDiv' + item);
      let imgMaskDom = document.querySelector('.imgMask' + item);
      let bigDivDom = document.querySelector('.bigDiv' + item);
      let bigImgkDom = document.querySelector('.bigImg' + item);

      let ev = e || window.event;
      let left =
        ev.clientX - smallDivDom.offsetLeft - imgMaskDom.offsetWidth / 2;
      let top =
        ev.clientY - smallDivDom.offsetTop - imgMaskDom.offsetHeight / 2;
      if (left < 0) left = 0;
      if (left > smallDivDom.offsetWidth - imgMaskDom.offsetWidth) {
        left = smallDivDom.offsetWidth - imgMaskDom.offsetWidth;
      }
      if (top < 0) top = 0;
      if (top > smallDivDom.offsetHeight - imgMaskDom.offsetHeight) {
        top = smallDivDom.offsetHeight - imgMaskDom.offsetHeight;
      }
      imgMaskDom.style.left = left + 'px';
      imgMaskDom.style.top = top + 'px';

      //移动比例
      let precentX = left / (smallDivDom.offsetWidth - imgMaskDom.offsetWidth);
      let precentY = top / (smallDivDom.offsetHeight - imgMaskDom.offsetHeight);
      bigImgkDom.style.left =
        precentX * (bigDivDom.offsetWidth - bigImgkDom.offsetWidth) + 'px';
      bigImgkDom.style.top =
        precentY * (bigDivDom.offsetHeight - bigImgkDom.offsetHeight) + 'px';
    },
    oncompanygetcompanyinfo() {
      companygetcompanyinfo({ companyid: this.cid }).then(res => {
        this.cmpinfo = res.data.result;
      });
    },
    ontobuy(item) {
      let accessToken = getCookieByName('access_token');
      if (!accessToken) {
        this.$refs.headerchild.dialogVisible = true;
        this.$refs.headerchild.onappCodeRefreshButton();
        return;
      }
      let mid = this.measureid;
      if (this.ismeasure && this.measureid == 0) {
        this.$message.error('请添加采集数据');
        return;
      }
      if (!this.ismeasure) {
        mid = 0;
      }

      console.log(this.dataform);

      this.$router.push({
        path: '/shopping/getorder',
        query: {
          fromoa: this.fromoa,
          customid: this.id,
          buynum: this.buynum,
          price: this.rprice,
          customstyle: JSON.stringify(this.dataform),
          dataid: mid
        }
      });
    },
    onclickattrdetail(isload, item, custom) {
      this.dataform[item.attrCode + '_id'] = custom.id;
      this.dataform[item.attrCode + '_price'] = custom.customPrice;
      this.dataform[item.attrCode + '_rprice'] = custom.retailDiscountPrice;
      this.dataform[item.attrCode + '_piclist'] = [];
      if (custom.imageList.length > 0) {
        for (let index = 0; index < custom.imageList.length; index++) {
          const element = custom.imageList[index];
          this.dataform[item.attrCode + '_piclist'].push({ id: element.id });
        }
      }
      this.dataform[item.attrCode + '_styletitle'] = custom.customName;
      this.dataform[item.attrCode + '_atttitle'] = item.attrTitle;
      this.ongetprice(this.dataform);
      if (custom.imageList.length > 0) {
        this.onclickattrdetailimg(item, custom.imageList[0]);
        if (isload) {
          this.ongetslideimglist(item, custom.imageList);
        }
      }
      if (item.attrCode == '数据采集' && custom.customName == '自己采集') {
        this.ismeasure = true;
        //this.onusermeasuredatagetlist();
      }
      if (
        item.attrCode.indexOf('数据采集') > -1 &&
        custom.customName == '上门服务'
      ) {
        this.ismeasure = false;
      }

      this.$forceUpdate();
    },
    ongetslideimglist(item, imglist) {
      this.slidesimages = [];
      for (let i = 0; i < imglist.length; i++) {
        let img = {
          url: imglist[i].imagePath,
          bigurl: imglist[i].imageRawPath,
          id: imglist[i].id
        };
        this.slidesimages.push(img);
      }

      console.log(this.slidesimages);
      this.imgurl = imglist[0].imagePath;
    },
    onusermeasuredatagetlist() {
      var that = this;
      usermeasuredatagetlist({ count: 0 }).then(res => {
        if (res.data.code === 1) {
          that.collectlist = res.data.result;
          if (res.data.result.length > 0) {
            let item = res.data.result[0];
            if (that.measureid == 0) {
              that.measureid = item.id;
              that.currmeasure =
                item.realname +
                ' ' +
                item.sex +
                ' 年龄：' +
                this.ongetage(item.birthday) +
                ' 身高：' +
                item.height +
                'CM' +
                ' 体重：' +
                item.weight +
                'KG';
            }
          } else {
            this.currmeasure = '请添加采集数据';
          }
        }
      });
    },
    onusermeasuredatadel(id) {
      let form = { id: id };
      usermeasuredatadel(form).then(res => {
        if (res.data.code === 1) {
          if (this.measureid == id) {
            this.measureid = 0;
          }
          this.onusermeasuredatagetlist();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    ongetprice(obj) {
      var _this = this;
      var tmpprice = 0;
      var tmprprice = 0;
      var piclist = [];
      Object.keys(obj).forEach(function(key) {
        if (key.indexOf('_price') > -1) {
          tmpprice += parseFloat(obj[key]);
        }
        if (key.indexOf('_rprice') > -1) {
          tmprprice += parseFloat(obj[key]);
        }
        if (key.indexOf('_piclist') > -1) {
          piclist.push(...obj[key]);
        }
      });
      _this.price = tmpprice;
      _this.rprice = tmprprice;
      _this.detailinfoimglist = piclist;
    },
    onclickattrdetailimg(item, img) {
      this.dataform[item.attrCode + '_img'] = img.id;
      this.$forceUpdate();
    },
    handleChange(value) {
      this.buynum = value;
    },
    isShow() {
      this.show = !this.show;
    },
    hideDetail() {
      this.detailShow = false;
    },
    onimgpreview(imgurl, index) {
      this.currindex = index;
      this.imgurl = imgurl.imagePath;
    },
    onproductgetcustomdetail() {
      var that = this;
      productgetcustomdetail({ id: that.id }).then(res => {
        if (res.data.code === 1) {
          this.goodsInfo = res.data.result;
          this.cid = res.data.result.companyId;
          this.customDescribe = res.data.result.customDescribe;
          this.oncompanygetcompanyinfo();
          //this.customName=res.data.result.customName;
          var tmpdataform = {};
          for (var i = 0; i < this.goodsInfo.customAttrList.length; i++) {
            var item = this.goodsInfo.customAttrList[i];
            if (i == 0) {
              var itemStyle = this.goodsInfo.customAttrList[0];
              if (itemStyle.customAttrDetailList.length > 0) {
                if (itemStyle.customAttrDetailList[0].imageList.length > 0) {
                  this.ongetslideimglist(
                    itemStyle,
                    itemStyle.customAttrDetailList[0].imageList
                  );
                }
              }
            }
            tmpdataform[item.attrCode + '_id'] = item.id;
            if (item.customAttrDetailList.length > 0) {
              var custom = item.customAttrDetailList[0];
              this.onclickattrdetail(false, item, custom);
              if (custom.imageList.length > 0) {
                var img = custom.imageList[0];
                this.onclickattrdetailimg(item, img);
              }
            }
          }
          this.$forceUpdate();
        }
      });
    },
    onproductgetspecialproductlist() {
      var that = this;
      productgetspecialproductlist({ count: 4 }).then(res => {
        if (res.data.code === 1) {
          that.productlist = res.data.result;
        }
      });
    }
  },
  watch: {},
  mounted() {
    screenLeft;
  },
  created() {
    this.id = this.$route.query.id;
    this.fromoa = this.$route.query.fromoa;
    this.onproductgetcustomdetail();
    this.onproductgetspecialproductlist();
    this.onusermeasuredatagetlist();
  }
};
</script>
<style scoped>
body {
  background-color: #eaecef;
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.special-ico {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
  background-color: #fff;
}
.content {
  width: 100%;
  margin-top: 20px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 700px;
}

.clearfix {
  clear: both;
}

.container_special {
  margin: 20px auto;
}
.container_row {
  margin: 0px auto;
}
.container_row_spec {
  width: 220px;
  height: 303px;
  background-color: #fff;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.2s linear;
}
.container_row_spec:hover {
  transform: translate3d(0, -2px, 0);
}
.container_row_img {
  cursor: pointer;
}
/* .container_row_spec:nth-child(5),
.container_row_spec:nth-child(10) {
  margin-right: 0px !important;
} */
.container_row_name {
  color: #666;
  height: 35px;
  font-size: 12px;
  width: 100%; /* margin-top: -4px; */
  background-color: #fff;
  word-break: break-all;
  overflow: hidden;
  line-height: 40px;
}
.container_row_name img {
  position: relative;
  top: 5px;
  margin-left: 9px;
}
.container_row_price {
  font-size: 20px;
  color: #fff;
  width: 150px;
}
.container_row_price .rmb {
  font-size: 12px;
  color: #fff;
}
.container_row_spec {
  height: auto;
}
.container_row_price .mode {
  font-size: 12px;
  color: #666;
}

.product-zoomer {
  width: 400px;
}
.content .wrap-box {
  width: 1200px;
}
.content .wrap-box .left-925 {
  width: 925px;
  float: left;
}
.content .wrap-box .left-220 {
  width: 220px;
  float: right;
}
.content .wrap-box .pic-box {
  width: 400px;
  float: left;
  height: 200px;
}
.content .wrap-box .goods-spec {
  width: 350px;
  float: left;
}
.section_head {
  background: #ff292e;
  font-size: 30px;
  height: 88px;
  line-height: 88px;
  text-align: center;
  color: #fff;
}
.section_head a {
  color: #fff;
}

.section_detail {
  width: 800px;
}
.picbox {
  float: left;
  width: 350px;
  height: 350px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
}
.picbox ul li {
  width: 50px;
  height: 50px;
  border: 2px solid #dedede;
  padding: 3px;
  margin-top: 6px;
}
.picbox ul li.current {
  border: 2px solid #e4393c;
}

.picbox ul {
  overflow: hidden;
  width: 431px;
  overflow: auto;
}
.descbox {
  margin-left: 20px;
  float: left;
  width: 730px;
  min-height: 600px;
}
.left-220 {
  margin-left: 20px;
  height: 600px;
  float: right;
}
.picbox > ul > li {
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
}
.hr {
  margin: 15px 0;
  border-bottom: 1px solid #e2e2e2;
}
.thing_name {
  font-size: 18px;
  font-weight: 600;
  word-break: break-all;
  overflow: hidden;
  padding: 6px;
  padding-left: 10px;
}
.thing_konwn {
  padding-left: 15px;
  font-size: 14px;
  color: #ff4068;
  margin-bottom: 3px;
}
.thing_place {
  background-image: url(../../assets/img/priceback.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 50px;
  line-height: 40px;
  position: relative;
  background-size: 100%;
}
.thing_every {
  position: relative;
}
.font_grey {
  margin-left: 10px;
  color: #8b8682;
  font-size: 10px;
}
.thing-sku .thing-sku-title {
  flex: 1;
  display: flex;
  float: left;
  height: 42px;
  line-height: 42px;
  width: 60px;
  font-size: 14px;
}
.thing_margin ul.thing-sku-customattr {
  padding-left: 90px;
}
.thing_margin ul.thing-sku-customattr span.customname.selected,
.thing-sku >>> .el-image.selected {
  border: 1px solid #e4393c;
}
.thing_margin ul.thing-sku-customattr span.customname.customstyle .el-image {
  margin-left: 0;
}
.thing_margin ul.thing-sku-customattr span.customname .customstyletit {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.thing_margin .sku-image {
  margin-left: 90px;
}
.thing_margin .sku-image span.customname.selected,
.thing-sku >>> .el-image.selected {
  border: 1px solid #e4393c;
}
.thing_margin .sku-image span.customname.customstyle .el-image {
  margin-left: 0;
}
.thing_margin .sku-image span.customname .customstyletit {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.thing-sku ul {
  /* flex: 1; */
  /* display: flex; */
  /* float: left; */
}
.thing_oldprice {
  margin-left: 50px;
  font-size: 14px;
}
.thing_newprice {
  margin-left: 35px;
  font-size: 28px;
  color: #e4393c;
  font-weight: 700;
  position: relative;
  top: 5px;
}
.hotshop {
  margin-bottom: 12px;
}

.thing_margin {
}
.thing_margin ul {
  padding-top: 4px;
}
.thing_margin ul.thing-sku-customattr span.customname {
  border: 1px solid #ccc;
  padding: 4px;
  margin: 3px;
  min-width: 37px;
  display: block;
  float: left;
  text-align: center;
  background-color: #f7f7f7;
  color: #666;
  cursor: pointer;
}
.thing_margin ul.sku-image span.customname {
  border: 1px solid #ccc;
  padding: 4px;
  margin: 3px;
  min-width: 37px;
  display: block;
  float: left;
  text-align: center;
  background-color: #f7f7f7;
  color: #666;
  cursor: pointer;
}
.thing_margin ul li >>> .el-image {
  width: 34px;
  height: 34px;
  margin-left: 3px;
  padding: 2px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.shoppingbtns {
  margin-top: 10px;
}
.shoppingother {
  margin: 10px 0 20px 0;
}
.summary-line {
  height: 0;
  overflow: hidden;
  border-bottom: 1px dotted #dfdfdf;
  margin-bottom: 15px;
  margin-top: 15px;
}
.shoppingnum {
  font-size: 14px;
}
.shoppingnum .el-input-number {
  width: 80px;
}
.shoppingnum >>> .el-input-number .el-input-number__increase,
.shoppingnum >>> .el-input-number .el-input-number__decrease {
  width: 20px;
}
.thing_margin .btnbuy {
  border-radius: 0;
  width: 180px;
  background-color: #ffeded;
  border-color: #e4393c;
  color: #fff;
  font-weight: 1000;
  font-size: 17px;
  background-color: #e4393c;
}
.thing_margin .saleout {
  border-radius: 0;
  width: 180px;
  color: #fff;
  font-weight: 1000;
  font-size: 17px;
  cursor: default;
}
.thing_sale {
  height: 30px;
  line-height: 30px;
  padding-left: 0px;
  margin-left: 0px;
  position: absolute;
  right: 30px;
  top: 4px;
}
.thing_sale .font_grey {
  margin-left: 10px;
}
.font_#e4393c {
  color: #e4393c;
  font-weight: 600;
  margin-left: 5px;
}
.right_left {
  color: #bebebe;
  margin: 0 30px;
  font-size: 16px;
}
.number {
  margin-left: 50px;
  width: 45px;
  height: 30px;
}
.number_up {
  border: 1px solid #bebebe;
  margin-top: -20px;
  width: 15px;
  height: 13px;
  cursor: pointer;
}
.number_down {
  border: 1px solid #bebebe;
  margin-left: -19px;
  margin-top: 13px;
  width: 15px;
  height: 13px;
  cursor: pointer;
}
.font-dimgrey {
  color: #999;
  font-size: 10px;
  font-family: simsun;
}
.font-grey {
  color: #8b8682;
  font-size: 10px;
}
.margin-right > span {
  margin-right: 20px;
}
.right_img {
  margin-bottom: 10px;
  position: relative;
}
.right_name {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.left-925 {
  width: 925px;
}
.left-220 {
  width: 220px;
}

.content-left {
  width: 200px;
  float: left;
  margin-right: 10px;
}
.content-right {
  width: 915px;
  margin-bottom: 150px;
  float: left;
}
.content_left_first {
  width: 200px;
  height: 200px;
  border: 1px solid #e2e2e2;
  padding: 10px 0;
}
.content_store_name {
  font-size: 11px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  display: block;
}
.content_hr {
  margin: 10px 0;
  border-bottom: 1px solid #e2e2e2;
}
.content_grade {
  margin-left: 40px;
  width: 20px;
  background-color: orange#e4393c;
  color: yellow;
  height: 20px;
  text-align: center;
  float: left;
}
.content_mfw {
  margin: 20px 0 0 40px;
  font-size: 14px;
  letter-spacing: 4px;
}
.content_fs {
  color: #e4393c;
  font-size: 14px;
  margin: 3px 0 0 40px;
  letter-spacing: 1px;
}
.content_fs span {
  margin-right: 10px;
}
.myTabs > .active > a {
  color: #fff;
  border-top: 1px solid #e4393c;
  background-color: #e4393c;
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  height: 38px;
  border-bottom: 1px solid #e4393c;
  background-color: #f7f7f7;
}
.nav > li {
  position: relative;
  display: block;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 17px;
  /* border: 1px solid #ddd; */
  /* border-radius: 4px 4px 0 0; */
  position: relative;
  display: block;
  padding: 10px 15px;
  background: #fff;
  border-bottom: none;
  border-bottom: 1px solid #e4393c;
  line-height: 18px;
  height: 17px;
}
.content-right .shopnumber {
  height: 380px;
  border: 1px solid #ddd;
  padding: 5px 15px;
  height: auto;
  padding-bottom: 20px;
}
.dropdown {
  margin-left: 400px;
}
.content_tab_bottom {
  margin-top: 10px;
  float: left;
  width: 31%;
  overflow: hidden;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.content_tab_bottom span {
  color: #8b8682;
  font-size: 10px;
}
.container_row_price {
  position: absolute;
  bottom: 18px;
  background: rgba(0, 0, 0, 0.5019607843137255);
  padding: 1px 0;
}
.container_row_price span {
  display: inline-block;

  width: 100%;
  margin: 0 auto;
  text-align: center;
  text-align: left;
}
.product_desc {
  padding: 10px;
}

.picbox >>> .image-magnifier__zoom {
  border: 1px solid #ddd;
  background-color: #fff;
}

.picbox >>> .el-carousel__item.el-carousel__item--card.is-in-stage {
  text-align: center;
}
/*修改高度*/
.picbox >>> .el-carousel__container {
  height: 100px;
}
.picbox >>> .van-collapse-item__content {
  padding: 14px 0;
}
/*左右箭头的样式*/
.picbox >>> button.el-carousel__arrow.el-carousel__arrow--left,
button.el-carousel__arrow.el-carousel__arrow--right {
  font-size: 12px;
  height: 20px;
  width: 20px;
  background: #a099f0;
}
.picbox >>> .headImgStyle {
  width: 100px;
}
.picbox >>> .el-carousel__item--card {
  width: 100px;
}

.imgMerror {
  position: relative;
  margin-bottom: 10px;
  /* padding: 50px; */
}
.imgMerror .smallDiv {
  width: 34px;
  height: 34px;
  margin: 0 auto;
}
.imgMerror .smallDiv img {
  width: 100%;
  height: 100%;
}
.imgMerror .smallDiv .imgMask {
  width: 30px;
  height: 30px;
  background: transparent;
  opacity: 0.5;
  cursor: move;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

.imgMerror .bigDiv {
  width: 240px;
  height: 240px;
  position: absolute;
  left: 100px;
  top: -72px;
  overflow: hidden;
  display: none;
  z-index: 1000;
}
.imgMerror .bigDiv img {
  width: 100%;
  height: 100%;
  position: relative;
  margin-left: 0;
}
.measure-curr {
  padding-left: 93px;
}
.measure-curr .icon-edit {
  color: #dc2728;
  margin-left: 5px;
  font-weight: 700;
}
.thing_margin ul li img {
  width: 34px;
  height: 34px;
  margin-left: -3px;
  padding: 2px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.thing_margin .measure {
  border: 1px solid #ddd;
  padding: 6px 3px;
  min-width: 37px;
  display: block;
  float: left;
  text-align: center;
  background-color: #ffeded;
  color: #ff0036;
  font-weight: 700;
  cursor: pointer;
  border: 1px solid #e4393c;
}

.thing_margin >>> .el-dropdown {
  border: 1px solid #e4393c;
  padding: 3px 0;
}
.thing_margin >>> .el-dropdown .el-dropdown-link {
  width: 100%;
  display: inline-block;
}
.thing_margin >>> .el-dropdown .el-icon-arrow-down {
  right: 2px;
  position: absolute;
  top: 5px;
}
.consignee-content {
  overflow: hidden;
  /* max-height: 168px; */
}
.consignee-scrollbar {
  width: 100%;
}
.consignee-scroll {
  position: relative;
  zoom: 1;
}
.consignee-scroll .consignee-cont {
  margin: 0 10px 0 20px;
  overflow: hidden;
}

.consignee-scroll .consignee-cont li {
  list-style: none;
  height: 30px;
  margin: 6px 0;
  float: left;
  width: 99.8%;
}
.consignee-scroll .consignee-item {
  list-style: none;
  position: relative;
  border: 1px solid #ddd;
  height: 18px;
  line-height: 18px;
  padding: 5px 10px;
  width: 120px;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
}
.consignee-scroll .consignee-cont .addr-default {
  display: inline-block;
  *float: left;
  margin: 5px 10px;
  background-color: #999;
  color: #fff;
  padding: 0 3px;
  line-height: 20px;
}
.consignee-scroll .consignee-cont .consignee-item,
.consignee-scroll .consignee-cont .consignee-item-disable {
  float: left;
}
.consignee-scroll .consignee-item.item-hover,
.consignee-scroll .consignee-item.item-selected,
.consignee-scroll .consignee-item:hover {
  border: 2px solid #e4393c;
  padding: 4px 10px;
}
.consignee-scroll .consignee-item.item-selected b {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  overflow: hidden;
  background: url(//misc.360buyimg.com/user/purchase/2.0.0/css/i/selected-icon.png)
    no-repeat;
}
.consignee-scroll .consignee-cont .addr-detail {
  float: left;
  height: 30px;
  line-height: 30px;
}
.consignee-scroll .consignee-cont .addr-detail span {
  display: inline-block;
  margin-left: 10px;
  *float: left;
}
.consignee-scroll .consignee-cont .addr-ops,
.consignee-scroll .consignee-cont .op-btns {
  visibility: hidden;
  float: right;
  text-align: right;
  height: 30px;
  line-height: 30px;
}
.consignee-scroll .consignee-cont .op-btns.op-btns-show {
  visibility: visible;
}

.collectlist >>> .el-dialog {
  border: 6px solid #bbb;
  height: 280px;
  height: auto;
}
.collectlist >>> .el-dialog .el-dialog__body {
  padding: 30px 1px;
  padding-top: 10px;
}
.btnadd {
  position: absolute;
  top: 18px;
  left: 145px;
}
.originprice {
  font-size: 16px;
  color: rgb(60, 60, 60);
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 12px;
}
</style>
